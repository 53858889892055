import { computed, createContext, customRef, getAccountStorage, mapProp, model, Model, modelAction, prop, Ref } from '@weavix/mobx';
import { isEqual, pick, sortBy } from 'lodash';
import { observable, when } from 'mobx';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { myUserId } from '../my-profile-store/my-profile-store';
import { Channel } from './channel';
import { ChannelMessage } from './channel-message';
import { ChannelType } from '@weavix/models/src/channel/channel';
import { SyncService } from '@weavix/sync';
import { coerceToDate } from '@weavix/utils/coerce-to-date';
import { ChannelMessageArchiver } from './channel-message-archiver';

export const channelRef = customRef<Channel>((id: string) => channelsContext.get()?.getChannelById(id));

export const channelsContext = createContext<ChannelsStore>();

@model('ChannelsStore')
export class ChannelsStore extends Model({
    focusedChannel: prop<Ref<Channel> | undefined>(),
    selectedChannel: prop<Ref<Channel> | undefined>(),
    channelsMap: mapProp<string, Channel>(),
}) {
    loaded: Promise<any>;
    close: () => any;
    notebookChannelId: string;
    newMessage$: Subject<ChannelMessage> = new Subject();
    private temporaryChannelsMap = observable.map<string, Channel>();
    private messages = new Map<string, any>();
    private channelMessageArchiver = new ChannelMessageArchiver();

    @modelAction
    setFocusedChannel(channelId?: string) {
        this.focusedChannel = channelId ? channelRef(channelId) : undefined;
    }

    @modelAction
    setSelectedChannel(channelId?: string) {
        this.selectedChannel = channelId ? channelRef(channelId) : undefined;
    }

    get selectedChannelId() {
        return this.selectedChannel?.id;
    }

    get focusedChannelId() {
        return this.focusedChannel?.id;
    }

    get getNotebookChannelId() {
        return this.notebookChannelId;
    }

    constructor(topic?: string, messageTopic?: string, preferenceTopic?: string) {
        super();
        channelsContext.setDefault(this);
        const preferences = new Map<string, any>();
        const channels = new Map<string, any>();

        const getConversation = (channelId: string) => {
            const channel = channels.get(channelId);
            if (!channel || channel.pendingPeople?.includes(channel.partition)) return null;

            const message = this.messages.get(channelId);
            const preference = preferences.get(channelId);

            const readSequence = this.selectedChannel?.id === channelId ?
                channel.sequence :
                Math.max(channel?.readSequences?.[myUserId()] ?? 0, preference?.readSequence ?? 0);

            return {
                ...channel,
                lastMessage: message ? new ChannelMessage(message) : null,
                readSequence,
                notificationPreference: preference?.notification,
                favoriteIndex: preference?.favoriteIndex,
                isSnoozed: preference?.isSnoozed,
                updated: preference?.updated ?? channel.updated,
            } as any as Channel;
        };

        const refreshAll = () => {
            const sorted = [...channels.values()].sort((a, b) => {
                return (a.updated ?? '') < (b.updated ?? '') ? -1 : 1;
            });
            const records = sorted
                .map(x => x.id)
                .map(getConversation)
                .filter(x => x) as Channel[];
            const notebookChannel = records.find(x => x.isNotebook);
            if (notebookChannel) {
                this.notebookChannelId = notebookChannel.id;
            } else this.notebookChannelId = null;
            this.setConversations(records);
        };

        const refresh = (channelId: string, keys?: string[]) => {
            const record = getConversation(channelId);
            if (record) this.updatedConversation(record, keys);
            else this.deleteConversation(channelId);
        };

        let lastChannelId = '';
        const messageOptions = { name: 'channel-messages', topics: messageTopic, readonly: false };
        const preferenceOptions = { name: 'channel-preferences', topics: preferenceTopic, readonly: !!preferenceTopic };
        const channelOptions = { name: 'channels', topics: topic, readonly: !!topic };
        const emitters = [
            getAccountStorage<any>(messageOptions).Instance()
                .query({
                    index: (key: string) => {
                        const channelId = key.split('|')[0];
                        if (!channelId || channelId === lastChannelId) return false;
                        lastChannelId = channelId;
                        return true;
                    },
                })
                .on('load', values => {
                    values.forEach(message => {
                        this.messages.set(message.channelId, message);
                    });
                    refreshAll();
                    lastChannelId = '';
                })
                .on('update', ({ value }) => {
                    this.newMessage$.next(value);
                    const existing = this.messages.get(value.channelId);
                    if (!existing || new Date(existing.date) <= new Date(value.date)) {
                        this.messages.set(value.channelId, value);
                        refresh(value.channelId, ['lastMessage']);
                        lastChannelId = '';
                    }
                }),
            getAccountStorage<any>(preferenceOptions).Instance()
                .query()
                .on('load', values => {
                    values.forEach(preference => {
                        preferences.set(preference.id, preference);
                    });
                    refreshAll();
                })
                .on('update', ({ value, keys }) => {
                    const index = keys?.indexOf('notification');
                    if (index >= 0) keys[index] = 'notificationPreference';
                    preferences.set(value.id, value);
                    refresh(value.id, keys);
                }),
            getAccountStorage<any>(channelOptions).Instance()
                .query()
                .on('load', async values => {
                    const start = new Date().getTime();
                    channels.clear();
                    values.forEach(value => channels.set(value.id, value));
                    await Promise.all(values.map(value => this.archiveChannelMessages(value.id, coerceToDate(this.getArchived(value)), messageTopic)));
                    refreshAll();
                    console.log(`Loaded channels ${this.channels.length} in ${new Date().getTime() - start} ms`);
                })
                .on('delete', value => {
                    channels.delete(value.id);
                    refresh(value.id);
                    this.deleteChannelMessages(value.id);
                })
                .on('update', async ({ value, keys }) => {
                    if (keys?.includes('archived')) {
                        await this.archiveChannelMessages(value.id, coerceToDate(this.getArchived(value)), messageTopic);
                        refresh(value.id, ['lastMessage']);
                    }
                    channels.set(value.id, value);
                    refresh(value.id, keys);
                }),
        ];
        Object.defineProperty(this, 'loaded', { get: () => Promise.all(emitters.map(x => x.wait())) });
        Object.defineProperty(this, 'close', () => {
            getAccountStorage<any>(preferenceOptions).Instance().close();
            getAccountStorage<any>(messageOptions).Instance().close();
            getAccountStorage<any>(channelOptions).Instance().close();
        });
    }

    async deleteChannelMessages(channelId: string) {
        const store = getAccountStorage<any>('channel-messages').Instance();
        store.index.removeMany((id, index) => index?.startsWith(channelId));
    }

    async archiveChannelMessages(channelId: string, archived: Date, messageTopic: string) {
        const archivedMessageIds = await this.channelMessageArchiver.archiveChannelMessages(channelId, archived, messageTopic);
        if (archivedMessageIds.has(this.messages.get(channelId)?.id)) {
            this.messages.delete(channelId);
        }
    }

    @computed
    get channels() {
        return [...this.channelsMap.values()];
    }

    @computed
    get channelsSortedFirstRecent() {
        return sortBy([...this.channels, ...this.temporaryChannelsMap.values()],
            x => x.lastMessage ? x.lastMessage.date.toISOString() : x.created).reverse();
    }

    get unreadCount() {
        return this.channels.reduce((o, v) => o + v.unreadCount, 0);
    }

    @modelAction
    deleteConversation(id: string) {
        this.channelsMap.delete(id);
    }

    @modelAction
    updatedConversation(conversation: Channel, keys?: string[]) {
        if (this.channelsMap.has(conversation.id)) this.channelsMap.get(conversation.id)?.update(keys ? pick(conversation, keys) : conversation);
        else this.channelsMap.set(conversation.id, new Channel(conversation));
    }

    @modelAction
    setConversations(conversations: Channel[]) {
        this.channelsMap.clear();
        conversations.forEach(v => {
            this.channelsMap.set(v.id, new Channel(v));
        });
    }

    getChannelById(channelId: string) {
        return this.channelsMap.get(channelId) ?? this.temporaryChannelsMap.get(channelId);
    }

    getChannelByPersonIds(personIds: string[]): Channel {
        if (!personIds || personIds.length === 0) return;
        personIds = [...new Set(personIds)].sort();
        const channel = this.channelsSortedFirstRecent
            .filter(x => x.type === ChannelType.People)
            .filter(x => x.people?.length === personIds.length)
            .find(x => isEqual(x.people?.sort(), personIds));
        return channel;
    }

    async createGroup(people: string[], id?: string ) {
        const channel = await SyncService.instance.post<Channel>('/core/channels/groups', { people, id });
        await when(() => this.channelsMap.has(channel.id), { timeout: 40000 });
        return this.channelsMap.get(channel.id);
    }

    async createTemporary(people: string[]) {
        this.clearTemporary();
        people = [...new Set(people.concat([myUserId()]))].sort();

        if (people.length !== 1) {
            const existing = this.channels.find(x => x.type === ChannelType.People && isEqual(x.people.sort(), people));
            if (existing) return existing;
        }

        const channel = new Channel({ people, type: ChannelType.People, id: uuid(), created: new Date().toISOString() });
        channel.temporary = true;
        this.temporaryChannelsMap.set(channel.id, channel);
        return channel;
    }

    async ensureCreated(temporary: Channel) {
        if (!temporary.temporary) return temporary;
        temporary.temporary = false;

        const channel = await this.createGroup(temporary.people, temporary.id);
        // We don't have notification preferences in Web Radio yet. Removing for now.
        // await channel.updateNotification(temporary.notification);
        this.temporaryChannelsMap.delete(temporary.id);
        temporary.update(channel); // Temporary channel assumes new ID and everything (which means new channel gets pulled also)
        return channel;
    }

    clearTemporary() {
        this.temporaryChannelsMap.clear();
    }

    deleteTemporary(tempId: string) {
        this.temporaryChannelsMap.delete(tempId);
    }

    private getArchived(channel: Channel): Date | string | undefined {
        const archived = channel.archived;
        if (!archived) return undefined;
        // on ADCs, archived is a single date
        if (typeof archived === 'string' || (archived as unknown) instanceof Date) return archived;
        // on UDCs, archived is a map of userIds to dates
        return archived[myUserId()];
    }
}
